import styled from '@emotion/styled';
import { BUTTON_TYPES } from 'components/Button';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeButtonFollowingColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputFontSizeShape,
  getThemeInputHeightShape,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  getThemeTextColor,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';

export const WebinarRegistrationBlockWrapper = styled.div<IColor>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    height: '100%',
    padding: `120px 20px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)}`,
    [MEDIA_QUERIES.TABLET]: {
      paddingTop: '68px',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      paddingBottom: '60px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      paddingTop: '84px',
    },
  })
);

export const WebinarRegistrationBlockContainer = styled.div((props) => ({
  width: '100%',
  maxWidth: '1024px',
  margin: '0 auto',
  height: '100%',
  display: CSS_DISPLAY.GRID,
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  rowGap: '16px',
  gridTemplateColumns: '1fr 360px',
  gridTemplateRows: 'auto',
  [MEDIA_QUERIES.TABLET]: {
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    gridTemplateColumns: '1fr',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const WebinarRegistrationTextContainer = styled.div({
  width: '100%',
  height: '100%',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  alignItems: CSS_ALIGN.FLEX_START,
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_JUSTIFY_CONTENT.CENTER,
  },
});

export const Description = styled.div((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  p: {
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  'ul, ol': {
    li: {
      fontFamily: getThemeFontFamily(props),
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    },
  },
}));

export const Title = styled.div((props) => ({
  'h1, h2': {
    fontSize: '42px',
    lineHeight: '52px',
    fontWeight: 600,
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: '6px',
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const Subtitle = styled.div((props) => ({
  'h2, h3': {
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      textAlign: TEXT_ALIGN.CENTER,
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    },
  },
}));

export const WebinarRegistrationFormContainer = styled.div((props) => ({
  width: '100%',
  height: 'auto',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  alignSelf: CSS_ALIGN.START,
  padding: '24px',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  borderRadius: '12px',
  background: getThemeMainColor(props),
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 10%)',
  [MEDIA_QUERIES.TABLET]: {
    width: '80%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '70%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
    padding: '24px 20px',
  },
}));

export const FormContainer = styled.div((props) => ({
  width: '100%',
  height: '100%',
  position: CSS_POSITION.RELATIVE,
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  alignItems: CSS_ALIGN.FLEX_START,
  'h3, h4': {
    fontSize: '24px',
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontWeight: 500,
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: '10px',
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
}));

export const StyledDate = styled.div((props) => ({
  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
    fontWeight: 500,
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    fontFamily: getThemeFontFamily(props),
  },
}));

export const MessageContainer = styled.div((props) => ({
  width: '100%',
  height: '100%',
  position: CSS_POSITION.ABSOLUTE,
  background: getThemeMainColor(props),
  opacity: 0.95,
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  svg: {
    width: '60px',
    height: '60px',
  },
}));

export const StyledErroIconContainer = styled.div({
  width: '100%',
  height: 'auto',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  position: CSS_POSITION.RELATIVE,
  div: {
    top: '38px',
  },
});

export const StyledForm = styled.form<{ isLoading: boolean }>(
  ({ isLoading }) => ({
    width: '100%',
    height: '100%',
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
    alignItems: CSS_ALIGN.FLEX_START,
    input: {
      opacity: isLoading ? 0.2 : 1,
    },
  })
);

export const StyledFormLabel = styled.label((props) => ({
  marginBottom: '9px',
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  lineHeight: '20px',
}));

export const StyledFormInput = styled.input<{ isError?: boolean }>(
  ({ isError, ...props }) => ({
    width: '100%',
    height: getThemeInputHeightShape(props, TSHIRT_SIZE.M),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    float: 'none',
    clear: 'none',
    border: isError
      ? `1px solid ${getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)}`
      : `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    padding: `${getThemeInputPaddingShape(
      props,
      TSHIRT_SIZE.XS
    )} ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
    alignItems: CSS_ALIGN.CENTER,
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.L),
    lineHeight: getThemeInputHeightShape(props, TSHIRT_SIZE.XS),
    fontFamily: getThemeFontFamily(props),
  })
);

const FormSubmitButton = StyledFormInput.withComponent('button');

export const FormButton = styled(FormSubmitButton)((props) => ({
  background: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  color: getThemeMainColor(props),
  margin: `${getThemeGutter(props, TSHIRT_SIZE.XS)} 0 0`,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)} 15px 9px`,
  border: 'none',
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY).cursor,
  ':hover': {
    backgroundColor: 'rgba(244,72,98,.9)',
  },
}));

export const MessageTitle = styled.div((props) => ({
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 600,
  textAlign: TEXT_ALIGN.CENTER,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  color: getThemeTextColor(props, COLOR_SHADE.BLACK),
}));

export const MessageText = styled.div((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  fontWeight: 400,
  textAlign: TEXT_ALIGN.CENTER,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
}));

export const MessageButton = styled.button((props) => ({
  width: '142px',
  height: getThemeInputHeightShape(props, TSHIRT_SIZE.M),
  marginTop: '38px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  lineHeight: '14px',
  fontWeight: 500,
  border: `1px solid${getThemeTextColor(props, COLOR_SHADE.BLACK)}`,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  background: getThemeMainColor(props),
  color: getThemeTextColor(props, COLOR_SHADE.BLACK),
  fontFamily: getThemeFontFamily(props),
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY).cursor,
}));

export const ErrorMessage = styled.p((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontWeight: 400,
  color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
}));
